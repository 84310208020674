define('open-ethereum-pool/routes/account', ['exports', 'ember', 'open-ethereum-pool/config/environment'], function (exports, _ember, _openEthereumPoolConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var url = _openEthereumPoolConfigEnvironment['default'].APP.ApiUrl + 'api/accounts/' + params.login;
      return _ember['default'].$.getJSON(url).then(function (data) {
        data.login = params.login;
        return _ember['default'].Object.create(data);
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      _ember['default'].run.later(this, this.refresh, 5000);
    },

    actions: {
      error: function error(_error) {
        if (_error.status === 404) {
          return this.transitionTo('not-found');
        }
      }
    }
  });
});
define('open-ethereum-pool/routes/payments', ['exports', 'ember', 'open-ethereum-pool/models/payment', 'open-ethereum-pool/config/environment'], function (exports, _ember, _openEthereumPoolModelsPayment, _openEthereumPoolConfigEnvironment) {
	exports['default'] = _ember['default'].Route.extend({
		model: function model() {
			var url = _openEthereumPoolConfigEnvironment['default'].APP.ApiUrl + 'api/payments';
			return _ember['default'].$.getJSON(url).then(function (data) {
				if (data.payments) {
					data.payments = data.payments.map(function (p) {
						return _openEthereumPoolModelsPayment['default'].create(p);
					});
				}
				return data;
			});
		},

		setupController: function setupController(controller, model) {
			this._super(controller, model);
			_ember['default'].run.later(this, this.refresh, 5000);
		}
	});
});
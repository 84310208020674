define('open-ethereum-pool/controllers/application', ['exports', 'ember', 'open-ethereum-pool/config/environment'], function (exports, _ember, _openEthereumPoolConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend(Object.defineProperties({

    height: _ember['default'].computed('model.nodes', {
      get: function get() {
        var node = this.get('bestNode');
        if (node) {
          return node.height;
        }
        return 0;
      }
    }),

    roundShares: _ember['default'].computed('model.stats', {
      get: function get() {
        return parseInt(this.get('model.stats.roundShares'));
      }
    }),

    difficulty: _ember['default'].computed('model.nodes', {
      get: function get() {
        var node = this.get('bestNode');
        if (node) {
          return node.difficulty;
        }
        return 0;
      }
    }),

    hashrate: _ember['default'].computed('difficulty', {
      get: function get() {
        return this.getWithDefault('difficulty', 0) / _openEthereumPoolConfigEnvironment['default'].APP.BlockTime;
      }
    }),

    immatureTotal: _ember['default'].computed('model', {
      get: function get() {
        return this.getWithDefault('model.immatureTotal', 0) + this.getWithDefault('model.candidatesTotal', 0);
      }
    }),

    bestNode: _ember['default'].computed('model.nodes', {
      get: function get() {
        var node = null;
        this.get('model.nodes').forEach(function (n) {
          if (!node) {
            node = n;
          }
          if (node.height < n.height) {
            node = n;
          }
        });
        return node;
      }
    }),

    lastBlockFound: _ember['default'].computed('model', {
      get: function get() {
        return parseInt(this.get('model.lastBlockFound')) || 0;
      }
    }),

    roundVariance: _ember['default'].computed('model', {
      get: function get() {
        var percent = this.get('model.stats.roundShares') / this.get('difficulty');
        if (!percent) {
          return 0;
        }
        return percent.toFixed(2);
      }
    }),

    nextEpoch: _ember['default'].computed('height', {
      get: function get() {
        var epochOffset = (30000 - this.getWithDefault('height', 1) % 30000) * 1000 * this.get('config').BlockTime;
        return Date.now() + epochOffset;
      }
    })
  }, {
    config: {
      get: function get() {
        return _openEthereumPoolConfigEnvironment['default'].APP;
      },
      configurable: true,
      enumerable: true
    }
  }));
});
define('open-ethereum-pool/controllers/account', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    stats: _ember['default'].computed.reads('applicationController.model.stats'),

    roundPercent: _ember['default'].computed('stats', 'model', {
      get: function get() {
        var percent = this.get('model.roundShares') / this.get('stats.roundShares');
        if (!percent) {
          return 0;
        }
        return percent;
      }
    })
  });
});
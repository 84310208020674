define('open-ethereum-pool/models/payment', ['exports', 'ember'], function (exports, _ember) {

	var Payment = _ember['default'].Object.extend({
		formatAmount: _ember['default'].computed('amount', function () {
			var value = parseInt(this.get('amount')) * 0.000000001;
			return value.toFixed(8);
		})
	});

	exports['default'] = Payment;
});
define("open-ethereum-pool/templates/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3+c4330341",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 8
            },
            "end": {
              "line": 17,
              "column": 8
            }
          },
          "moduleName": "open-ethereum-pool/templates/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-clock-o");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Last Block Found: ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 2]), 0, 0);
          return morphs;
        },
        statements: [["inline", "format-relative", [["subexpr", "seconds-to-ms", [["get", "stats.model.stats.lastBlockFound", ["loc", [null, [16, 100], [16, 132]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 85], [16, 133]]], 0, 0]], [], ["loc", [null, [16, 67], [16, 135]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3+c4330341",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "open-ethereum-pool/templates/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "jumbotron");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-5");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5, "style", "font-size: 24px");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("strong");
        var el7 = dom.createTextNode("ETC Pool");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("strong");
        var el6 = dom.createTextNode("Min. payout threshold: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(", Payouts run twice per day.");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "label label-success");
        var el6 = dom.createTextNode("PROP");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Stable and profitable pool with regular payouts.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-3 stats");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa fa-users");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Miners Online: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "id", "poolHashrate");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa fa-tachometer");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Pool Hash Rate: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "id", "poolHashrate");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa fa-money");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Pool Fee: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "id", "poolFee");
        dom.setAttribute(el6, "class", "label label-success");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-4 stats");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa fa-unlock-alt");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Network Difficulty: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa fa-tachometer");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Network Hash Rate: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa fa-bars");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Blockchain Height: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa fa-clock-o");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Current Round Variance: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "stats");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h4");
        var el4 = dom.createTextNode("Your Stats & Payment History");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-group");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "input-group-btn");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "class", "btn btn-primary");
        dom.setAttribute(el5, "type", "button");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "style", "display: inline;");
        var el7 = dom.createElement("i");
        dom.setAttribute(el7, "class", "fa fa-search");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" Lookup");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("hr");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h4");
        dom.setAttribute(el3, "class", "note note-info text-center");
        var el4 = dom.createTextNode("\n      ethminer.exe -F ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(":");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("/<address>/<worker> -G\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element0, [5]);
        var element3 = dom.childAt(fragment, [2]);
        var element4 = dom.childAt(element3, [1, 3]);
        var element5 = dom.childAt(element4, [3, 1]);
        var element6 = dom.childAt(element3, [3, 3]);
        var morphs = new Array(13);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 2]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3, 2]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [5, 2]), 0, 0);
        morphs[4] = dom.createMorphAt(element1, 7, 7);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [1, 2]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [3, 2]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element2, [5, 2]), 0, 0);
        morphs[8] = dom.createMorphAt(dom.childAt(element2, [7, 2]), 0, 0);
        morphs[9] = dom.createMorphAt(element4, 1, 1);
        morphs[10] = dom.createElementMorph(element5);
        morphs[11] = dom.createMorphAt(element6, 1, 1);
        morphs[12] = dom.createMorphAt(element6, 3, 3);
        return morphs;
      },
      statements: [["content", "config.PayoutThreshold", ["loc", [null, [8, 39], [8, 65]]], 0, 0, 0, 0], ["inline", "format-number", [["get", "stats.model.minersTotal", ["loc", [null, [12, 96], [12, 119]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 80], [12, 121]]], 0, 0], ["inline", "format-hashrate", [["get", "stats.model.hashrate", ["loc", [null, [13, 104], [13, 124]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 86], [13, 126]]], 0, 0], ["content", "config.PoolFee", ["loc", [null, [14, 98], [14, 116]]], 0, 0, 0, 0], ["block", "if", [["get", "stats.model.stats.lastBlockFound", ["loc", [null, [15, 14], [15, 46]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [15, 8], [17, 15]]]], ["inline", "with-metric-prefix", [["get", "stats.difficulty", ["loc", [null, [20, 93], [20, 109]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 72], [20, 111]]], 0, 0], ["inline", "format-hashrate", [["get", "stats.hashrate", ["loc", [null, [21, 89], [21, 103]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 71], [21, 105]]], 0, 0], ["inline", "format-number", [["get", "stats.height", ["loc", [null, [22, 81], [22, 93]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 65], [22, 95]]], 0, 0], ["inline", "format-number", [["get", "stats.roundVariance", ["loc", [null, [23, 89], [23, 108]]], 0, 0, 0, 0]], ["style", "percent"], ["loc", [null, [23, 73], [23, 126]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "cachedLogin", ["loc", [null, [33, 20], [33, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "placeholder", "Enter Your ETC Address"], ["loc", [null, [33, 6], [33, 91]]], 0, 0], ["element", "action", ["lookup", ["get", "cachedLogin", ["loc", [null, [35, 72], [35, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 54], [35, 85]]], 0, 0], ["content", "config.HttpHost", ["loc", [null, [44, 22], [44, 41]]], 0, 0, 0, 0], ["content", "config.HttpPort", ["loc", [null, [44, 42], [44, 61]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});
define('open-ethereum-pool/models/block', ['exports', 'ember'], function (exports, _ember) {

	var Block = _ember['default'].Object.extend({
		variance: _ember['default'].computed('difficulty', 'shares', function () {
			var percent = this.get('shares') / this.get('difficulty');
			if (!percent) {
				return 0;
			}
			return percent;
		}),

		isLucky: _ember['default'].computed('variance', function () {
			return this.get('variance') <= 1.0;
		}),

		isOk: _ember['default'].computed('orphan', 'uncle', function () {
			return !this.get('orphan');
		}),

		formatReward: _ember['default'].computed('reward', function () {
			if (!this.get('orphan')) {
				var value = parseInt(this.get('reward')) * 0.000000000000000001;
				return value.toFixed(6);
			} else {
				return 0;
			}
		})
	});

	exports['default'] = Block;
});
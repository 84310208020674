define('open-ethereum-pool/routes/blocks', ['exports', 'ember', 'open-ethereum-pool/models/block', 'open-ethereum-pool/config/environment'], function (exports, _ember, _openEthereumPoolModelsBlock, _openEthereumPoolConfigEnvironment) {
	exports['default'] = _ember['default'].Route.extend({
		model: function model() {
			var url = _openEthereumPoolConfigEnvironment['default'].APP.ApiUrl + 'api/blocks';
			return _ember['default'].$.getJSON(url).then(function (data) {
				if (data.candidates) {
					data.candidates = data.candidates.map(function (b) {
						return _openEthereumPoolModelsBlock['default'].create(b);
					});
				}
				if (data.immature) {
					data.immature = data.immature.map(function (b) {
						return _openEthereumPoolModelsBlock['default'].create(b);
					});
				}
				if (data.matured) {
					data.matured = data.matured.map(function (b) {
						return _openEthereumPoolModelsBlock['default'].create(b);
					});
				}
				return data;
			});
		},

		setupController: function setupController(controller, model) {
			this._super(controller, model);
			_ember['default'].run.later(this, this.refresh, 5000);
		}
	});
});
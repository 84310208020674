define('open-ethereum-pool/routes/miners', ['exports', 'ember', 'open-ethereum-pool/config/environment'], function (exports, _ember, _openEthereumPoolConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var url = _openEthereumPoolConfigEnvironment['default'].APP.ApiUrl + 'api/miners';
      return _ember['default'].$.getJSON(url).then(function (data) {
        if (data.miners) {
          // Convert map to array
          data.miners = Object.keys(data.miners).map(function (value) {
            var m = data.miners[value];
            m.login = value;
            return m;
          });
          // Sort miners by hashrate
          data.miners = data.miners.sort(function (a, b) {
            if (a.hr < b.hr) {
              return 1;
            }
            if (a.hr > b.hr) {
              return -1;
            }
            return 0;
          });
        }
        return data;
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      _ember['default'].run.later(this, this.refresh, 5000);
    }
  });
});
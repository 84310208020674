define('open-ethereum-pool/router', ['exports', 'ember', 'open-ethereum-pool/config/environment'], function (exports, _ember, _openEthereumPoolConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _openEthereumPoolConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('account', { path: '/account/:login' }, function () {
      this.route('payouts');
    });
    this.route('not-found');

    this.route('blocks', function () {
      this.route('immature');
      this.route('pending');
    });

    this.route('help');
    this.route('payments');
    this.route('miners');
    this.route('about');
  });

  exports['default'] = Router;
});
define('open-ethereum-pool/controllers/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    stats: _ember['default'].computed.reads('applicationController'),
    config: _ember['default'].computed.reads('applicationController.config'),

    cachedLogin: _ember['default'].computed('login', {
      get: function get() {
        return this.get('login') || _ember['default'].$.cookie('login');
      },
      set: function set(key, value) {
        _ember['default'].$.cookie('login', value);
        this.set('model.login', value);
        return value;
      }
    })
  });
});
define('open-ethereum-pool/routes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      lookup: function lookup(login) {
        if (!_ember['default'].isEmpty(login)) {
          return this.transitionTo('account', login);
        }
      }
    }
  });
});
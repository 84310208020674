define('open-ethereum-pool/components/active-li', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNameBindings: ['isActive:active:inactive'],

    router: (function () {
      return getOwner(this).lookup('router:main');
    }).property(),

    isActive: (function () {
      var currentWhen = this.get('currentWhen');
      return this.get('router').isActive(currentWhen);
    }).property('router.url', 'currentWhen')
  });
});
define('open-ethereum-pool/routes/application', ['exports', 'ember', 'open-ethereum-pool/config/environment'], function (exports, _ember, _openEthereumPoolConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    intl: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      this.get('intl').setLocale('en-us');
    },

    model: function model() {
      var url = _openEthereumPoolConfigEnvironment['default'].APP.ApiUrl + 'api/stats';
      return _ember['default'].$.getJSON(url).then(function (data) {
        return _ember['default'].Object.create(data);
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      _ember['default'].run.later(this, this.refresh, 5000);
    }
  });
});